import { faCalendarPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, Colors, IconButton, OutlinedDropdown, Text } from 'library';
import React, { useEffect, useState } from 'react';
import { LocationTaskList, TaskListItem, TaskListSearchFilters } from './matchingInboxModels';
import { getTaskList } from './matchingInboxApi';
import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TablePagination } from '@mui/material';
import { PagingResult } from 'models';
import { GroupedTasks } from './components';
import TaskForm, { TaskFormOptions } from './TaskForm';
import { getTaskFormOptions } from './TaskFormFields';
import { AdminBusinessLocationOption } from 'models/AdminBusinessLocation';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
};

interface ITaskTypeOption {
    value: string;
    label: string;
}

function sortTaskTypes(a: ITaskTypeOption, b: ITaskTypeOption) {
    if (b.label < a.label) {
        return 1;
    } else if (b.label > a.label) {
        return -1;
    }
    return 0;
}

function mapTaskTypes(typeOption: ITaskTypeOption) {
    let label = typeOption.label;
    if (label === 'Location At Risk') {
        label = 'At Risk';
    }

    if (label === '[First] No Show') {
        label = 'First No Show';
    }

    return {
        ...typeOption,
        label,
    };
}

export default function TaskList({
    isOpen,
    businessLocation,
    addTask = false,
}: {
    isOpen: boolean;
    businessLocation?: AdminBusinessLocationOption;
    addTask?: boolean;
}) {
    const [locationTasks, setLocationTasks] = useState<PagingResult<LocationTaskList>>();
    const [searchFilters, setSearchFilters] = useState<TaskListSearchFilters>({
        page: 1,
        inbox: '',
        page_size: 5,
        business_location: businessLocation?.id,
        task_types: [],
    });
    const [isAdding, setIsAdding] = useState(addTask);
    const [options, setOptions] = useState<TaskFormOptions>({
        inboxes: [],
        types: [],
        default_inbox: null,
        businessLocation: businessLocation,
    });
    const [editItem, setEditItem] = useState<TaskListItem>();
    const [taskTypeOptions, setTaskTypeOptions] = useState<{ value: string; label: string }[]>([]);

    useEffect(() => {
        getTaskFormOptions().then((result) => {
            setSearchFilters((prev) => {
                return {
                    ...prev,
                    inbox: result.default_inbox?.key || '',
                };
            });
            setOptions({ ...result, businessLocation: businessLocation });
            setTaskTypeOptions(result.task_types.map(mapTaskTypes).sort(sortTaskTypes));
        });
    }, []);

    useEffect(() => {
        if (isOpen) {
            getTaskList({ ...searchFilters, business_location: businessLocation?.id }).then(setLocationTasks);
            if (businessLocation?.id !== options.businessLocation?.id) {
                setOptions({ ...options, businessLocation: businessLocation });
            }
        }
    }, [isOpen, searchFilters, businessLocation]);

    function refresh() {
        getTaskList({ ...searchFilters, business_location: businessLocation?.id }).then(setLocationTasks);
    }

    function onEdit(item: TaskListItem) {
        setEditItem(item);
    }

    function onResolve(item: TaskListItem) {
        const updated = locationTasks?.results.map((loc) => {
            const items = searchFilters.task_types?.includes('-2')
                ? loc.inbox_items.map((it) => (it.id === item.id ? { ...it, resolved: true } : it))
                : loc.inbox_items.filter((it) => it.id !== item.id);
            return { ...loc, inbox_items: items };
        });
        setLocationTasks((prev) => (prev && updated ? { ...prev, results: updated } : prev));
    }

    return (
        <Grid justifyContent="space-between">
            {isAdding || Boolean(editItem) ? (
                <TaskForm
                    options={options}
                    onClose={() => {
                        setIsAdding(false);
                        setEditItem(undefined);
                        refresh();
                    }}
                    inbox={options.inboxes.find((i) => i.key === searchFilters.inbox)}
                    editItem={editItem}
                />
            ) : (
                <>
                    <Grid>
                        <Grid container justifyContent="space-between">
                            <OutlinedDropdown
                                label="Assigned to"
                                onChange={(e) => setSearchFilters({ ...searchFilters, inbox: e.target.value })}
                                value={searchFilters.inbox}
                                fields={options.inboxes}
                                style={{ width: 200 }}
                            />
                            {taskTypeOptions.length > 0 && (
                                <FormControl sx={{ m: 1, width: 200 }}>
                                    <InputLabel id="task-types-label" style={{ maxHeight: 40 }}>
                                        Task Types
                                    </InputLabel>
                                    <Select
                                        labelId="task-types-label"
                                        multiple
                                        value={searchFilters.task_types}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setSearchFilters({
                                                ...searchFilters,
                                                task_types: typeof value === 'string' ? value.split(',') : value,
                                            });
                                        }}
                                        input={<OutlinedInput style={{ maxWidth: 250, maxHeight: 40 }} label="Tag" />}
                                        renderValue={(selected) =>
                                            taskTypeOptions
                                                .filter((x) => selected.includes(x.value))
                                                .map((x) => x.label)
                                                .join(', ')
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {taskTypeOptions.map((x) => (
                                            <MenuItem key={x.value} value={x.value}>
                                                <Checkbox
                                                    checked={searchFilters?.task_types?.includes(x.value) || false}
                                                    label={x.label}
                                                    onChange={() => {}}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            <IconButton
                                tooltip="Refresh"
                                onClick={refresh}
                                FontAwesomeImg={faRefresh}
                                color={Colors.white}
                                backgroundColor={Colors.mediumNavy}
                                iconStyle={styles.icon}
                                style={styles.iconButton}
                            />
                            <IconButton
                                tooltip="Add Task"
                                onClick={() => setIsAdding(!isAdding)}
                                FontAwesomeImg={faCalendarPlus}
                                color={Colors.white}
                                backgroundColor={Colors.mediumNavy}
                                iconStyle={styles.icon}
                                style={styles.iconButton}
                            />
                        </Grid>
                        {locationTasks && locationTasks?.results.length > 0 ? (
                            locationTasks?.results.map((location) => (
                                <GroupedTasks
                                    key={location.id}
                                    locationTaskList={location}
                                    refresh={refresh}
                                    onEdit={onEdit}
                                    onResolve={onResolve}
                                />
                            ))
                        ) : (
                            <Text>You have finished everything! 💪</Text>
                        )}
                    </Grid>
                    <TablePagination
                        component="div"
                        count={locationTasks?.count || 0}
                        rowsPerPage={locationTasks?.per_page || 0}
                        page={searchFilters.page - 1}
                        onPageChange={(_, page) => setSearchFilters({ ...searchFilters, page: page + 1 })}
                        rowsPerPageOptions={[]}
                    />
                </>
            )}
        </Grid>
    );
}

const styles = {
    iconButton: {
        marginBottom: 20,
    },
    icon: {
        marginTop: 5,
    },
};
